<template>
    <div class="user-index">
        <div class="main-h1">Просмотр дела</div>
        <div class="card">
            <div class="card-title">{{ claim.title }} ({{claim.type_title}})</div>
            <div class="card-body">
                <div class="card-body-title">Участники</div>
                <div class="row">
                    <div :class="$store.state.isAdmin() || $store.state.isImplementor() ? 'col-3' : 'col-4'">
                        <label class="form-label">
                            <div class="t">Истец ({{ claim.claimant_type }})</div>
                            <input v-model="claim.claimant_name" disabled/>
                        </label>
                    </div>
                    <div :class="$store.state.isAdmin() || $store.state.isImplementor() ? 'col-3' : 'col-4'">
                        <label class="form-label">
                            <div class="t">Арбитр</div>
                            <input v-model="claim.arbiter_name" disabled/>
                        </label>
                    </div>
                    <div :class="$store.state.isAdmin() || $store.state.isImplementor() ? 'col-3' : 'col-4'">
                        <label class="form-label">
                            <div class="t">Ответчик ({{ claim.defendant_type }})</div>
                            <input v-model="claim.defendant_name" disabled/>
                        </label>
                    </div>
                    <div class="col-3" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Агент</div>
                            <input v-model="claim.agent_name" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Стадия</div>
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Стадия</div>
                            <input v-model="claim.stage_title" disabled/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Дедлайн стадии</div>
                            <input v-model="claim.stage_dl" disabled/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Ответственный</div>
                            <input v-model="claim.implementer_name" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Даты</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Иск принят</div>
                            <input v-model="claim.receipt_date" disabled/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Рассмотрение</div>
                            <input v-model="claim.consideration_date" disabled/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Вынесено решение</div>
                            <input v-model="claim.decision_date" disabled/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Выдан исполнительный лист</div>
                            <input v-model="claim.writ_date" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Суммы</div>
                <div class="row">
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Сумма иска</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount).toLocaleString()" disabled/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isClaimant()">
                        <label class="form-label">
                            <div class="t">Арбитражный сбор</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount_sbor).toLocaleString()" disabled/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isClaimant()">
                        <label class="form-label">
                            <div class="t">Возврат арбитражного сбора</div>
                            <input :value="parseInt(claim.amount_sbor_out).toLocaleString()" disabled v-if="$store.state.isAdmin() || $store.state.isImplementor()"/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Гонорар арбитра</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount_arbiter).toLocaleString()"
                                   disabled/>
                        </label>
                    </div>
                    <div class="col-fg-1" v-if="$store.state.isAdmin() || $store.state.isImplementor()">
                        <label class="form-label">
                            <div class="t">Вознаграждение агента</div>
                            <input v-if="claim.amount" :value="parseInt(claim.amount_agent || 0).toLocaleString()"
                                   disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body" v-if="files.filter(item => item.type_id == 1).length">
                <div class="card-body-title">Документы</div>
                <file-list :list="files.filter(item => item.type_id == 1)" @updated="updateFiles"/>
            </div>
            <notification-list v-if="notifications.length" :list="notifications" @updated="updateNotification"/>
            <div class="card-body" v-if="files.filter(item => item.type_id == 3).length">
                <div class="card-body-title">Записи процессов</div>
                <file-list :list="files.filter(item => item.type_id == 3)" @updated="updateFiles"/>
            </div>
            <div class="card-body" v-if="files.filter(item => item.type_id == 4).length || $store.state.isArbiter()">
                <div class="card-body-title">Решение</div>
                <file-list :list="files.filter(item => item.type_id == 4)" @updated="updateFiles"/>
                <upload-file v-if="$store.state.isArbiter()" doc_type="4" parent_type="claim" :parent_id="claim.id"
                             @uploaded="updateFiles"/>
            </div>
            <!--
            <payment-list v-if="payments.length" :list="payments" @updated="updatePayments"/>
            <payment-create v-if="$store.state.isBuh()" :parent_id="claim.id" @uploaded="updatePayments"/>
            -->
            <div class="card-body">
                <div class="card-body-title">Обращения</div>
                <router-link :to="`/panel/appeal/create_user/toclaim/${this.claim.id}`" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter()" class="btn blue inb mb-20">
                    Подать обращение
                </router-link>
                <appeal-list :list="claim.appeals" v-if="claim.appeals && claim.appeals.length"/>
            </div>
            <div class="card-body" v-if="connected.length && ($store.state.isAdmin() || $store.state.isImplementor())">
                <div class="card-body-title">Связанные иски</div>
                <claim-list v-if="connected.length" :list="connected"/>
            </div>
            <div class="card-body"
                 v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter() || $store.state.isClaimant() || $store.state.isDefendant()">
                <div class="card-body-title">Файлы</div>
                <button :disabled="isAllFilesWaiting" class="btn blue inb mb-20" @click="downloadAllFiles">Скачать все
                    файлы
                </button>
            </div>
            <div class="card-body" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter()">
                <div class="card-body-title">История</div>
                <button class="btn blue" @click="logGet" v-if="!log.length">Получить</button>
                <table class="table-log" v-if="log.length">
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Автор</th>
                        <th>Изменение</th>
                    </tr>
                    </thead>
                    <tr v-for="item in log">
                        <td>{{ item.date }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.text }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import fileList from "../../../components/file/list";
import uploadFile from "../../../components/file/upload";
import notificationList from "../../../components/notification/list";
//import paymentList from "../../../components/payment/list"
//import paymentCreate from "../../../components/payment/create"
import claimList from '../../../components/claim/list.vue';
import appealList from '../../../components/appeal/list'

export default {
    name: "viewClaim",
    components: {uploadFile, fileList, notificationList, claimList, appealList},
    data() {
        return {
            claim: {},
            log: [],
            files: [],
            notifications: [],
            //payments: [],
            connected: [],
            isAllFilesWaiting: false,
        }
    },
    methods: {
        updateFiles() {
            this.getFiles();
            //this.logGet();
        },
        updateNotification() {
            this.getNotifications();
            //this.logGet();
        },
        updatePayments() {
            this.getPayments();
            //this.logGet();
        },
        getClaimConnected(claimId) {
            this.$api.get(`claim/${claimId}`).then(res => {
                if (res.data.success) {
                    this.connected.push(res.data.data)
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getClaim() {
            this.$api.get(`claim/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data
                    //this.logGet();
                    if (res.data.data.connected.length) {
                        res.data.data.connected.forEach(claimId => {
                            this.getClaimConnected(claimId)
                        })
                    }
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        logGet() {
            this.$api.get(`claim/${this.$route.params.id}/log`).then(res => {
                if (res.data.success) {
                    this.log = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getFiles() {
            this.$api.get(`claim/${this.$route.params.id}/file`).then(res => {
                if (res.data.success) {
                    this.files = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getNotifications() {
            this.$api.get(`claim/${this.$route.params.id}/notification`).then(res => {
                if (res.data.success) {
                    this.notifications = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getPayments() {
            this.$api.get(`claim/${this.$route.params.id}/payment`).then(res => {
                if (res.data.success) {
                    this.payments = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        downloadAllFiles() {
            this.isAllFilesWaiting = true;
            this.$api.get(`claim/${this.$route.params.id}/all-files`).then(res => {
                this.isAllFilesWaiting = false;
                if (res.data.success) {
                    const linkSource = `data:${res.data.data.mime_type};base64,${res.data.data.base64}`;
                    const downloadLink = document.createElement('a');
                    document.body.appendChild(downloadLink);
                    downloadLink.href = linkSource;
                    downloadLink.target = '_self';
                    downloadLink.download = `${res.data.data.name}`;
                    downloadLink.click();
                    downloadLink.remove();
                    this.$emit('updated');
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        }
    },
    created() {
        this.getClaim();
        this.getFiles();
        this.getNotifications();
        //this.getPayments();
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id) {
                this.connected = [];
                this.files = [];
                this.notifications = [];
                this.getClaim();
                this.getFiles();
                this.getNotifications();
                //this.getPayments();
            }
        }
    }
}
</script>

<style scoped>
.amount-sbor-wrap {
    display: flex;
    gap:10px;
}
</style>